* {
    box-sizing: border-box;
  }
  
  html,
  body {
    overscroll-behavior-y: contain;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    user-select: none;
    font-family: Raleway, sans-serif;
    position: fixed;
    overflow: hidden;
  }
  
  #root {
  
    background: #ED213A;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #93291E, #ED213A);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #93291E, #ED213A); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    
  
  
    /* background: #e52d27;  
    background: -webkit-linear-gradient(to right, #b31217, #e52d27); 
    background: linear-gradient(to right, #b31217, #e52d27);  */
    
  
  
    /* background-color: #fffffc;
    background-image: linear-gradient(315deg, #fffffc 0%, #beb7a4 74%); */
    
    
    
    /* background-color: #d3d3d3;
    background-image: linear-gradient(315deg, #d3d3d3 0%, #eccc68 74%); */
    
    
    position: fixed;
    overflow: hidden;
    width: 100%;
    height: 100%;
    cursor: url("https://uploads.codesandbox.io/uploads/user/b3e56831-8b98-4fee-b941-0e27f39883ab/Ad1_-cursor.png")
        39 39,
      auto;
  }
  
  #root > div {
    position: absolute;
    width: 100vw;
    height: 100vh;
    will-change: transform;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  #root > div > div {
    padding: 5px;
    background-color: white;
    background-size: auto 85%;
    background-repeat: no-repeat;
    background-position: center center;
    width: 300px;
    max-width: 300px;
    height: 550px;
    max-height: 570px;
    will-change: transform;
    border-radius: 10px;
    box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.4),
      0 10px 10px -10px rgba(50, 50, 73, 0.3);
  }
  
  img {
    height: 380px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  button[aria-label="previous"],
  button[aria-label="next"] {
    visibility: hidden;
    background: salmon !important;
    color: black !important;
  }
  
  .MovieName {
    font-size: 20px;
    display: inline-block;
    margin-right: 10px;
  }
  
  .MovieYear{
    font-size: 15px;
    display: inline-block;
    margin-right: 10px;
    opacity: 0.7;
    font-size: 1.35rem;
  }
  
  .MovieRating{
    font-size: 15px;
    margin-top: -15px;
    margin-left: 2px;
    color: gray;
  }
  
  .MoviePlot{
    font-size: 13px;
    margin-top: -15px;
    margin-left: 2px;
    color: gray;
  }


  h2 {
    display: inline-block;
    margin-right: 10px;
  }
  
  h2:nth-of-type(2) {
    opacity: 0.7;
    font-size: 1.35rem;
  }
  
  h5 {
    margin-top: -15px;
    margin-left: 2px;
    color: lightgray;
  }
  h5:last-of-type {
    color: darkgray;
  }
  
  .card > div {
    height: 380px !important;
  }
  
  .card {
    height: 500px !important;
  }
  
  .slider-control-bottomcenter {
    bottom: -15px !important;
  }
  
  .slider-control-bottomcenter > ul > li > button {
    color: salmon !important;
  }
  